import moment from "moment";
import {
    surgeries_fullPageImage,
    surgeries_insertText,
    surgeries_tick,
    surgeries_getProcedureTitle,
    surgeries_getBillingCodeNumbers,
    surgeries_getProcedureDescription,
    surgeries_surgeonSign,
    convertPDFToImages,
    surgeries_formatDate,
    surgeries_blankPage} from "../utils";
import {
    surgeries_adsOnlineAdmission_page,
    surgeries_adsPreAdmissionChild_page,
    surgeries_adsPreAdmissionAdult_page,
    surgeries_calRequestForAdmission_page,
    surgeries_calEAdmission_page,
    surgeries_calPatientHealthAssessment_page,
    surgeries_calPatientRegistrationDetails_page,
    surgeries_wgAdmissionQuestionnaire_page,
    surgeries_wgPatientRegistration_page
} from "../img/admission";

export async function surgeries_admissionForm(doc, surgery) {
    switch (surgery.clinic.name) {
        case "Adelaide Day Surgery":
            doc.content.push(...surgeries_adsOnlineAdmission());
            surgery.print_admission && doc.content.push(...surgeries_adsPreAdmission(surgery));
            return;
        case "Calvary North Adelaide":
            doc.content.push(...surgeries_calRequestForAdmission(surgery));
            doc.content.push(...surgeries_calEAdmission());
            surgery.print_admission && doc.content.push(...surgeries_calPatientHealthAssessment(surgery));
            surgery.print_admission && doc.content.push(...surgeries_calPatientRegistrationDetails(surgery));
            return;
        case "Windsor Gardens Day Surgery":
            surgery.print_admission && doc.content.push(...surgeries_wgAdmissionQuestionnaire(surgery));
            surgery.print_admission && doc.content.push(...surgeries_wgPatientRegistration(surgery));
            doc.content.push(...await surgeries_wgOnlineAdmission(surgery));
            return;
        case "Port Pirie Regional Health Service":
        case "Port Augusta Hospital":
            const content = await surgeries_ruralAdmission(surgery);
            doc.content.push(...content);
            return;
        default: return;
    }
}

function surgeries_adsOnlineAdmission() {
    return [
        surgeries_fullPageImage(surgeries_adsOnlineAdmission_page(0)),
        surgeries_blankPage(),
    ];
}

function surgeries_adsPreAdmission(data) {
    return [{stack: [
        surgeries_fullPageImage(surgeries_adsPreAdmissionChild_page(0)),
        surgeries_insertText(surgeries_formatDate(data.surgery.date, "DD"), 130, 175),
        surgeries_insertText(surgeries_formatDate(data.surgery.date, "MM"), 155, 175),
        surgeries_insertText(surgeries_formatDate(data.surgery.date, "YYYY"), 180, 175),
        surgeries_insertText(data.clinician.name, 310, 176),
        surgeries_insertText(surgeries_getProcedureDescription(data), 110, 192, 'form_small'),
        surgeries_insertText(data.patient.salutation, 85, 293),
        surgeries_insertText(data.patient.surname, 190, 293),
        surgeries_insertText(data.patient.given_names, 430, 293),
        surgeries_insertText(data.patient.male ? "Male" : "Female", 255, 326),
        surgeries_insertText(surgeries_formatDate(data.patient.dob, "DD"), 117, 325),
        surgeries_insertText(surgeries_formatDate(data.patient.dob, "MM"), 142, 325),
        surgeries_insertText(surgeries_formatDate(data.patient.dob, "YYYY"), 166, 325),

        surgeries_fullPageImage(surgeries_adsPreAdmissionChild_page(1)),
        surgeries_fullPageImage(surgeries_adsPreAdmissionAdult_page(2)),
        surgeries_insertText(surgeries_formatDate(data.surgery.date, "DD"), 125, 158),
        surgeries_insertText(surgeries_formatDate(data.surgery.date, "MM"), 150, 158),
        surgeries_insertText(surgeries_formatDate(data.surgery.date, "YYYY"), 175, 158),
        surgeries_insertText(data.patient.salutation, 85, 190),
        surgeries_insertText(data.patient.surname, 185, 190),
        surgeries_insertText(data.patient.given_names, 420, 190),
        surgeries_insertText(data.patient.male ? "Male" : "Female", 85, 204),
        surgeries_insertText(surgeries_formatDate(data.patient.dob, "DD"), 282, 204),
        surgeries_insertText(surgeries_formatDate(data.patient.dob, "MM"), 307, 204),
        surgeries_insertText(surgeries_formatDate(data.patient.dob, "YYYY"), 332, 204),

        surgeries_fullPageImage(surgeries_adsPreAdmissionAdult_page(3)),
        surgeries_fullPageImage(surgeries_adsPreAdmissionAdult_page(4)),
        surgeries_fullPageImage(surgeries_adsPreAdmissionAdult_page(5)),
    ]}];
}

function surgeries_calRequestForAdmission(data) {
    return [{stack: [
        surgeries_fullPageImage(surgeries_calRequestForAdmission_page(0)),
        surgeries_insertText(data.patient.surname, 360, 53),
        surgeries_insertText(data.patient.given_names, 380, 77),
        surgeries_insertText(surgeries_formatDate(data.patient.dob, 1), 340, 99),
        (data.patient.male ? surgeries_tick(337, 130, 8, 8) : surgeries_tick(364, 130, 8, 8)),
        surgeries_insertText(data.clinician.name, 60, 192),
        surgeries_insertText(surgeries_getProcedureTitle(data), 60, 221),
        surgeries_insertText(surgeries_getProcedureDescription(data), 60, 250),
        surgeries_insertText(surgeries_getBillingCodeNumbers(data).join(", "), 60, 338),
        (data.surgery.anaesthetic === "general" ? surgeries_tick(111, 390, 8, 8) : surgeries_tick(137, 390, 8, 8)),
        surgeries_insertText(surgeries_formatDate(data.surgery.date, 1), 170, 395),
        surgeries_insertText(surgeries_formatDate(data.surgery.date, 1), 170, 408),
        surgeries_insertText("Patient to advise", 60, 490),
        surgeries_insertText("Patient to advise", 60, 576),
        surgeries_surgeonSign(data.clinician.name, 180, 740, 40),
        surgeries_insertText(moment().format("DD/MM/yyyy"), 394, 759),
    ]}];
}

function surgeries_calEAdmission() {
    return [
        surgeries_fullPageImage(surgeries_calEAdmission_page(0)),
        surgeries_fullPageImage(surgeries_calEAdmission_page(1)),
        surgeries_fullPageImage(surgeries_calEAdmission_page(2)),
        surgeries_blankPage(),
    ];
}

function surgeries_calPatientHealthAssessment(data) {
    return [...Array(4).keys()].map(page => surgeries_fullPageImage(surgeries_calPatientHealthAssessment_page(page)));
}

function surgeries_calPatientRegistrationDetails(data) {
    return [{stack: [
        surgeries_fullPageImage(surgeries_calPatientRegistrationDetails_page(0)),
        surgeries_insertText(data.patient.surname, 360, 53),
        surgeries_insertText(data.patient.given_names, 380, 77),
        surgeries_insertText(surgeries_formatDate(data.patient.dob, 1), 340, 99),
        (data.patient.male ? surgeries_tick(335, 130, 8, 8) : surgeries_tick(362, 130, 8, 8)),
        surgeries_insertText(surgeries_formatDate(data.surgery.date, 1), 120, 186, 'form_small'),
        surgeries_insertText(data.clinician.name, 400, 187),
        surgeries_insertText(data.patient.salutation, 85, 230),
        surgeries_insertText(data.patient.surname, 175, 230),
        surgeries_insertText(data.patient.given_names, 375, 230),
        surgeries_insertText(data.patient.male ? "Male" : "Female", 92, 243),
        surgeries_insertText(surgeries_formatDate(data.patient.dob, 1), 190, 243),
        surgeries_fullPageImage(surgeries_calPatientRegistrationDetails_page(1)),
    ]}];
}

function surgeries_wgAdmissionQuestionnaire(data) {
    return [
        surgeries_fullPageImage(surgeries_wgAdmissionQuestionnaire_page(0)),
        surgeries_insertText(data.patient.surname, 150, 118),
        surgeries_insertText(data.patient.given_names, 355, 118),
        surgeries_insertText(surgeries_formatDate(data.patient.dob, 1), 150, 154),
        surgeries_insertText(surgeries_formatDate(data.surgery.date, 1), 355, 154),
        surgeries_insertText(data.clinician.name, 165, 171),
        surgeries_fullPageImage(surgeries_wgAdmissionQuestionnaire_page(1)),
        surgeries_fullPageImage(surgeries_wgAdmissionQuestionnaire_page(2)),
        surgeries_fullPageImage(surgeries_wgAdmissionQuestionnaire_page(3)),
    ];
}

async function surgeries_wgOnlineAdmission(data) {
    const admission_pages = await convertPDFToImages("forms/wg_online_admission.pdf");
    return [
        surgeries_fullPageImage(admission_pages[0]),
        surgeries_fullPageImage(admission_pages[1]),
    ];
}

function surgeries_wgPatientRegistration(data) {
    return [
        surgeries_fullPageImage(surgeries_wgPatientRegistration_page(0)),
        surgeries_insertText(surgeries_formatDate(data.surgery.date, 1), 140, 136),
        surgeries_insertText(data.clinician.name, 370, 136),
        surgeries_insertText(surgeries_getProcedureDescription(data), 140, 206),
        surgeries_insertText(data.patient.surname, 140, 235),
        surgeries_insertText(data.patient.given_names, 360, 235),
        surgeries_insertText(data.patient.salutation, 510, 235),
        surgeries_insertText(surgeries_formatDate(data.patient.dob, 1), 140, 264),
        (data.patient.male ? surgeries_tick(411, 273, 7, 7) : surgeries_tick(448, 273, 7, 7)),
        surgeries_fullPageImage(surgeries_wgPatientRegistration_page(1)),
    ];
}

async function surgeries_ruralAdmission(surgery) {
    const admission_pages = await convertPDFToImages("forms/rural_admission.pdf");
    const patient_dob = new moment(surgery.patient.dob);

    return [
        {stack: [
            surgeries_fullPageImage(admission_pages[0]),
            surgeries_insertText(surgery.clinic.name, 120, 95),
            surgeries_insertText(surgery.patient.surname, 355, 36),
            surgeries_insertText(surgery.patient.given_names, 380, 57),
            surgeries_insertText(patient_dob.format("DD/MM/YYYY"), 350, 97),
            surgeries_insertText(surgery.patient.male ? "Male" : "Female", 470, 97),
            surgeries_tick(211, 526, 11, 11),

            surgeries_fullPageImage(admission_pages[1]),
            surgeries_insertText(surgery.patient.surname, 375, 37),
            surgeries_insertText(surgery.patient.given_names, 385, 53),
            surgeries_insertText(patient_dob.format("DD/MM/YYYY"), 370, 85),
            surgeries_insertText(surgery.patient.male ? "Male" : "Female", 505, 85),
            surgeries_insertText(surgeries_formatDate(undefined, "DD"), 135, 120),
            surgeries_insertText(surgeries_formatDate(undefined, "MM"), 174, 120),
            surgeries_insertText(surgeries_formatDate(undefined, "YYYY"), 203, 120),
            surgeries_insertText(surgery.clinician.name, 120, 139),
            surgeries_insertText("Ophthalmology", 360, 139),
            surgeries_insertText(surgeries_getProcedureTitle(surgery), 150, 173),
            surgeries_insertText(surgeries_getProcedureDescription(surgery), 150, 193),
            surgeries_insertText(surgeries_getBillingCodeNumbers(surgery).join(", "), 190, 212),
            surgeries_tick(172, 439, 12, 12),
            surgeries_insertText(`${surgery.surgery.left_eye ? "Left" : "Right"} eye`, 70, 632),
            surgeries_insertText(surgery.clinician.name, 160, 783),
            surgeries_surgeonSign(surgery.clinician.name, 120, 795, 30),
            surgeries_insertText(surgeries_formatDate(undefined, "DD"), 345, 805),
            surgeries_insertText(surgeries_formatDate(undefined, "MM"), 380, 805),
            surgeries_insertText(surgeries_formatDate(undefined, "YYYY"), 412, 805),
        ]}
    ];
}
