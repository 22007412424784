import {
    surgeries_getApplicableGaps,
    surgeries_getHeader,
    surgeries_getTitle,
    surgeries_formatDate,
    surgeries_getProcedureDescription,
    surgeries_getBillingCodeNumbers,
    surgeries_blankPage,
} from "../utils";

function surgeries_calculateGap(surgery) {
    let total = 0;
    surgeries_getApplicableGaps(surgery).forEach(gap => total += gap.gap);
    return total === 0 ? NaN : Math.min(total, 500);
}

export function surgeries_financialConsentForm(doc, surgery) {
    const blankLine = '_______________';
    const notApplicable = 'N/A';
    const tbaTheatre = 'To be advised by theatre';
    const tbaAnaesthetist = 'To be advised by anaesthetist';
    const payableMessage = '(Payable to Pennington Eye Clinic before surgery)';

    var surgeonFee;
    var theatreFee;
    var anaesthetistFee;
    var assistantFee;
    var outOfPocket;

    if (surgery.surgery.gap) {
        if (surgery.patient.health_fund === "Self-funding") {
            surgeonFee = blankLine;
            theatreFee = tbaTheatre;
            anaesthetistFee = tbaAnaesthetist;
            assistantFee = blankLine;
            outOfPocket = blankLine;
        } else {
            surgeonFee = surgeries_calculateGap(surgery);
            surgeonFee = isNaN(surgeonFee) ? blankLine : `$${surgeonFee}`;
            theatreFee = notApplicable;
            anaesthetistFee = notApplicable;
            assistantFee = notApplicable;
            outOfPocket = surgeonFee;
        }
    } else {
        surgeonFee = blankLine;
        theatreFee = blankLine;
        anaesthetistFee = blankLine;
        assistantFee = blankLine;
        outOfPocket = blankLine;
    }

    doc.content.push([
        surgeries_getHeader(true),
        surgeries_getTitle(`Informed Financial Consent - Surgeon Gap`),
        {
            layout: 'noBorders',
            table: {
                heights: 20,
                body: [
                    ['Date:', surgeries_formatDate(undefined, 2)],
                    ['Patient:', `${surgery.patient.salutation} ${surgery.patient.given_names} ${surgery.patient.surname}`],
                    ['DOB:', surgeries_formatDate(surgery.patient.dob, 2)],
                ]
            }
        },
        {
            margin: [0, 10, 0, 0],
            layout: 'noBorders',
            table: {
                widths: [120, 'auto'],
                heights: 37,
                body: [
                    [{text: 'Proposed Operation:', style: 'tableHeader'}, surgeries_getProcedureDescription(surgery)],
                    [{text: 'Anticipated MBS item number(s):', style: 'tableHeader'}, surgeries_getBillingCodeNumbers(surgery).join(', ')],
                    [{text: 'Date of Surgery:', style: 'tableHeader'}, surgeries_formatDate(surgery.surgery.date, 3)],
                    [{text: 'Day Surgery:', style: 'tableHeader'}, surgery.clinic.name],
                    [{text: 'Health Fund:', style: 'tableHeader'}, surgery.patient.health_fund],
                    [{text: 'Surgeon Gap:', style: 'tableHeader'}, `${surgeonFee} ${payableMessage}`],
                    [{text: 'Theatre Fee:', style: 'tableHeader'}, theatreFee],
                    [{text: 'Anaesthetist Fee:', style: 'tableHeader'}, anaesthetistFee],
                    [{text: 'Assistant Fee (Retinal Surgery):', style: 'tableHeader'}, assistantFee],
                    [{text: 'Total Out of Pocket:', style: 'tableHeader'}, `${outOfPocket} ${payableMessage}`],
                    ['', ''],
                    [{text: 'Patient Signature:', style: 'tableHeader'}, blankLine + blankLine],
                    [{text: 'Witness Signature:', style: 'tableHeader'}, blankLine + blankLine],
                ],
            }
        },
        {
            bold: true,
            alignment: 'center',
            text: `This gap is separate to the excess that may be charged by your private health insurance upon hospital admission`
        },
        surgeries_blankPage(),
    ]);
}
