import { convertPDFToImages, surgeries_fullPageImage } from "../utils";

const procedure_file_map = {
    "cataract" : "procedure_info/RVMfaVRID1XMUm3f_cataract.pdf",
    "conjunctiva" : "procedure_info/lf3WSDsDBWiabdgO_conjunctival_biopsy.pdf",
    "epiretinal_membrane" : "procedure_info/2fDjBiq9wO8bEMU1_epiretinal_membrane.pdf",
    "face_down_posturing" : "procedure_info/oOto4M4nYuZ0N6zo_face_down_posturing.pdf",
    "macular_hole" : "procedure_info/SWsrArH3HRKcucDj_macular_hole.pdf",
    "pterygium" : "procedure_info/eIhjlHb8OA92Sm1V_pterygium.pdf",
    "retinal_detachment" : "procedure_info/dOuJIm0xU6UweK02_retinal_detachment.pdf",
    "vitrectomy" : "procedure_info/77h9yfmb6Mn7iQ2s_vitrectomy.pdf",
}

export async function surgeries_postOpInfoForm(doc, data) {
    data.procedure.cataract && await surgeries_postOpInfo(doc, procedure_file_map["cataract"]);
    data.procedure.conjunctiva && await surgeries_postOpInfo(doc, procedure_file_map["conjunctiva"]);
    data.procedure.epiretinal_membrane && await surgeries_postOpInfo(doc, procedure_file_map["epiretinal_membrane"]);
    if (data.procedure.macular_hole) {
        await surgeries_postOpInfo(doc, procedure_file_map["macular_hole"]);
        await surgeries_postOpInfo(doc, procedure_file_map["face_down_posturing"]);
    }
    data.procedure.pterygium && await surgeries_postOpInfo(doc, procedure_file_map["pterygium"]);
    data.procedure.retinal_detachment && await surgeries_postOpInfo(doc, procedure_file_map["retinal_detachment"]);
    data.procedure.vitrectomy && await surgeries_postOpInfo(doc, procedure_file_map["vitrectomy"]);
}

async function surgeries_postOpInfo(doc, file) {
    const pages = await convertPDFToImages(file);
    doc.content.push(...pages.map(page => surgeries_fullPageImage(page)));
}
