import {convertPDFToImages, surgeries_blankPage, surgeries_fullPageImage} from "../utils";
import {
    surgeries_advancedAnaestheticsQuote_page,
    surgeries_staceQuoteRequest_page
} from "../img/quotes";

export async function surgeries_quoteForms(doc, data) {
    if (data.patient.health_fund === "Self-funding") {
        switch (data.clinic.name) {
            case "Adelaide Day Surgery":
                doc.content.push([
                    ...await surgeries_adsQuoteRequest(),
                    ...surgeries_advancedAnaestheticsQuote(),
                ]);
                return;
            case "Calvary North Adelaide":
                doc.content.push([
                    ...surgeries_advancedAnaestheticsQuote(),
                ]);
                return;
            case "Windsor Gardens Day Surgery":
                doc.content.push([
                    ...surgeries_staceQuoteRequest(),
                ]);
                return;
            default: return;
        }
    }
}

function surgeries_advancedAnaestheticsQuote() {
    return [
        surgeries_fullPageImage(surgeries_advancedAnaestheticsQuote_page(0)),
        surgeries_blankPage(),
    ];
}

async function surgeries_adsQuoteRequest() {
    const pages = await convertPDFToImages("forms/ads_quote_request.pdf");
    return [
        surgeries_fullPageImage(pages[0]),
        surgeries_blankPage(),
    ];
}

function surgeries_staceQuoteRequest() {
    return [
        surgeries_fullPageImage(surgeries_staceQuoteRequest_page(0)),
        surgeries_blankPage(),
    ];
}
