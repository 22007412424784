import axios from 'axios';
import config from './config';
import Surgery from './types/Surgery';

const login = async (username, password) => {
    return await api_post("/api/login", {username: username, password: password});
}

const getSurgeries = async (client_id) => {
    const surgeries = await api_get("/api/surgeries", {client_id: client_id});
    var result = {};
    if (surgeries && surgeries.length > 0) {
        surgeries.forEach(element => {
            result[element.surgery_id] = new Surgery(element);
        });
    }
    console.log("Returning", result)
    return result;
}

const getClinics = async (client_id) => {
    const clinics = await api_get("/api/clinics", {client_id: client_id});
    console.log("Got clinics", clinics)
    return clinics;
}

const getClinicians = async () => {
    const clinicians = await api_get("/api/clinicians");
    console.log("Returning", clinicians)
    return clinicians;
}

const getLastModified = async () => {
    const last_modified = await api_get("/api/last_modified");
    if (last_modified) return last_modified[0].max;
    console.log("Failed to get last modified")
}

const getNextSurgery = async () => {
    const next_surgery_id = await api_get("/api/next_surgery");
    console.log("result from create", next_surgery_id)
    return parseInt(next_surgery_id.id);
}

const setSurgery = async (surgery) => {
    await api_post("/api/set_surgery", surgery);
}

const deleteSurgery = async (id) => {
    console.log("Deleting surgery", id)
    await api_post("/api/delete_surgery", {id: id});
}

const api_get = async (path, params={}) => {
    try {
        const response = await axios.get(`${config.SERVER_ENDPOINT}${path}`, {params: params});
        console.log("Response:", response);
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};

const api_post = async (path, data) => {
    try {
        const response = await axios.post(`${config.SERVER_ENDPOINT}${path}`, data);
        console.log('Data added successfully:', response.data);
        return response.data
    } catch (error) {
        console.error('There was an error adding the data!', error);
    }
}

export default {
    login,
    getSurgeries,
    getClinics,
    getClinicians,
    getLastModified,
    getNextSurgery,
    setSurgery,
    deleteSurgery,
}
